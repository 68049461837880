import React, { useState, useEffect } from 'react';
import briefcaseIcon from '../../assets/icons/briefcase_job.svg';
import groupIcon from '../../assets/icons/group_job.svg';
import locationIcon from '../../assets/icons/location_job.svg';
import { useParams } from 'react-router-dom';
import { fetchData } from '../../services/apiServices'; 
import BackButton from '../../components/ui/BackButton';

const JobDetailPage = () => {
  const { jobId } = useParams();  // Get the jobId from the URL
  const [jobDetail, setJobDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  // Function to handle button click and open the modal
  const handleApplyClick = () => {
    setIsModalVisible(true);
  };

  // Function to handle modal close
  const closeModal = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    const fetchJobDetail = async () => {
      setLoading(true);
      setError(null);  // Reset any previous errors
      try {
        // API endpoint for getting job details
        const url = `get-candidate-job-details?jobId=${jobId}&login_token=${localStorage.getItem('login_token')}&userId=${localStorage.getItem('login_token')}`;
        
        const response = await fetchData(url, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
        });

        // Check if the response code is 200 and data is available
        if (response.code === 200 && response.jobDetails) {
          setJobDetail(response.jobDetails);  // Set the job details
        } else {
          setError("Job not found");
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
        setError("Something went wrong while fetching job details.");
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetail();
  }, [jobId]);  // Fetch job details whenever the jobId changes


const applyForJob = async (jobId, appliedFlag) => {
  const url = 'user-job-action'; // Your API endpoint

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      appliedFlag,
      jobId,
      login_token: localStorage.getItem('login_token'), // Retrieve login_token from localStorage
      userId: localStorage.getItem('login_token') // Assuming userId is the same as login_token
    }),
  };

  try {
    const response = await fetchData(url, options);
    const data = await response.json();
    if (data.code === 200) {
      console.log('Job action successful:', data);
      // Handle success (e.g., show a success message, update state)
    } else {
      console.error('Job action failed:', data);
      // Handle error (e.g., show an error message)
    }
  } catch (error) {
    console.error('Error applying for job:', error);
    // Handle network or other errors
  }
};
//applyForJob('2483', 'Saved');

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return jobDetail ? (
    <>
    <section className="main_container jobpage main_container_new">
		    <div className="container">
			    <BackButton/>
				<div className="card big_card">
          <div className='job_top card-head_'>
             <h2>{jobDetail.job_title}</h2>
             <div className='d-flex_'>
             <ul className='job_top_txt'>
              <li>
                  <img src={briefcaseIcon} alt="Job Icon" />
                  <span>{jobDetail.minimum_exp} - {jobDetail.maximum_exp} Years</span>
              </li>
              <li>
                  <img src={groupIcon} alt="Breafcase Icon" />
                  <span>{jobDetail.dni_category}</span>
              </li>
              <li>
                  <img src={locationIcon} alt="Location Icon" />
                  <span>{jobDetail.city_name}</span>
              </li>
             </ul>
             </div>
             <div className='d-flex w-100'>
             <p>Posted on {jobDetail.posted}  
              {jobDetail.totalApplicants ? (
                <strong>• {jobDetail.totalApplicants} Applicants</strong>
              ) : null}</p>
             </div>
             <div className='d-flex justify-content-end align-items-center mb-4'>
             {jobDetail.userJobDetails && jobDetail.userJobDetails.length > 0 && jobDetail.userJobDetails[0].Saved == 1 ? (
  <a 
    className='btn btn-primary btn-trans me-2 p-2 px-4' 
    onClick={() => applyForJob(jobDetail.id, 'Saved')}
  >
    Saved
  </a>
) : (
  <a 
    className='btn btn-secondary btn-trans me-2 p-2 px-4' 
    onClick={() => applyForJob(jobDetail.id, 'NotSaved')}
  >
    Save
  </a>
)}


                {jobDetail.applied_job_link ? (
                <a className='btn btn-primary btn-trans_ me-2  p-2 px-4' onClick={handleApplyClick}>
                  Apply Now
                </a>
                ):(
                <>
                <a className='btn btn-primary btn-trans_ me-2  p-2 px-4' href={jobDetail.applied_job_link} target="_blank" rel="noopener noreferrer">
                  Applied
                </a>
                </>
                )
                }
             </div>
             
              
          </div>
      <div className='job_body py-4'>
      <h5><stron>Overview</stron></h5>
      <p>{jobDetail.job_details}</p>
      <p><strong>What you’ll do</strong></p>
      <p>{jobDetail.key_responsibilities}</p>
      <p><strong>Role: </strong>{jobDetail.role_name}</p>
      <p><strong>Industry Type : </strong>{jobDetail.industry_name}</p>
      <p><strong>Department : </strong></p>
      <p><strong>Employment Type:</strong> {jobDetail.employement_type}</p>
      {/* <h5>Education</h5> */}
      <p><strong>Company: </strong>{jobDetail.employer_name}</p>
      <p><strong>Posted by: </strong>{jobDetail.posted_by}</p>
      <p><strong>Experience: </strong>{jobDetail.minimum_exp} - {jobDetail.maximum_exp} years</p>
      <p><strong>Salary Range: </strong>{jobDetail.salary_range}</p>
      {/* <p><strong>Key skill: </strong> {jobDetail.skill}</p> */}
      <p><strong>Key skill: </strong></p>
       <p>{jobDetail.skill && jobDetail.skill.split(',').map((skill, index) => (
          <span key={index} style={{ display:'inline-block',marginRight: '8px',marginBottom: '8px',borderRadius:'15px',color:'#474D6A', padding:'0px 9px',border:'1px solid #E7E7F1',}}>
            {skill.trim()}
          </span>
        ))}
      </p>
      </div>
      {/* Add more job details here as needed */}
       {/* Bootstrap Modal */}
       {isModalVisible && (
        <div className="modal fade show d-block applyjob" tabIndex="-1" role="dialog">
          <div className="modal-dialog p-4" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className='d-flex flex-column'>
                <img className='popuplogo' src={jobDetail.employer_logo} alt=""/>
                <h2>{jobDetail.job_title}</h2>
                <p style={{fontWeight:'400',fontSize:'14px'}}>{jobDetail.employer_name || "Not specified"} •{" "}
                {jobDetail.employement_type || "Not specified"} •{" "}
                {jobDetail.city_name || "Not specified"}</p>
                <p className='py-3'>You will be redirected to the company’s job portal to apply</p>
                <a className='btn btn-primary btn-trans_ mb-2  p-2 px-4' href={jobDetail.applied_job_link} target="_blank" rel="noopener noreferrer">Take me there</a>
                <a href="#" type="button" className="btn btn-secondary_" onClick={closeModal}>
                  Cancel
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Backdrop for modal */}
      {isModalVisible && <div className="modal-backdrop fade show"></div>}
        </div>
      </div>
    </section>
    </>
  ) : (
    <p>Job details not available.</p>
  );
};

export default JobDetailPage;
