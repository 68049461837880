import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserData } from '../../services/profileServices';
import ProfileCard from '../../components/profile/ProfileCard';
import ToggleComponent from '../../components/ui/ToggleComponent ';
import icons from '../../assets/icons';
import ProfileComplete from '../../components/ui/CompleteStatus';
import TabNav from '../../components/ui/TabNav';

const ProfileOverview = () => {
  const location = useLocation();
  const userId = localStorage.getItem('login_token');
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Fetch profile data
  useEffect(() => {
    if (userId) {
      getUserData(userId)
        .then((response) => {
          if (response.code === 200) {
            setProfileData(response.data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error loading user data:", error);
          setIsLoading(false);
        });
    }
  }, [userId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <section className='content'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3'>
            <ProfileCard/>
          </div>
          <div className='col-md-6'>
            <h3>Profile Overview</h3>
            <TabNav/>
            <div className="card no-border shadow">
              <div className="card-body">
                <h5>Name: {profileData.user_data[0]?.name || 'N/A'}</h5>
                <h5>Email: {profileData.user_data[0]?.email || 'N/A'}</h5>
                <h5>DOB: {profileData.user_data[0]?.dob || 'N/A'}</h5>
                <h5>D & I Category: {profileData.user_data[0]?.dni_category || 'N/A'}</h5>
                <h5>Gender: {profileData.user_data[0]?.gender || 'N/A'}</h5>
                <h5>Job Role: {profileData.user_data[0]?.job_role || 'N/A'}</h5>
                <h5>Experience: {profileData.user_data[0]?.experience || 'N/A'} years</h5>
              </div>
            </div>

            <ToggleComponent title="Work Experience" icon={icons['briefcase.svg']}>
              {profileData.work_experience && profileData.work_experience.length > 0 ? (
                profileData.work_experience.map((work, index) => (
                  <div key={index}>
                    <h6>Company Name: {work.company_name || 'N/A'}</h6>
                    <h6>Role: {work.role_name || 'N/A'}</h6>
                    <h6>Start Date: {work.start_date || 'N/A'}</h6>
                    <h6>End Date: {work.end_date || 'N/A'}</h6>
                    <h6>Description: {work.description || 'N/A'}</h6>
                    <h6>Current Job: {work.is_current_company ? 'Yes' : 'No'}</h6>
                  </div>
                ))
              ) : (
                <p>No work experience found.</p>
              )}
            </ToggleComponent>
            <ToggleComponent title="Education" icon={icons['briefcase.svg']}>
              {profileData.education_data && profileData.education_data.length > 0 ? (
                profileData.education_data.map((edu, index) => (
                  <div key={index}>
                    <h6>Company Name: {edu.degree || 'N/A'}</h6>
                    <h6>Role: {edu.university || 'N/A'}</h6>
                    <h6>Passing Year: {edu.passing_year || 'N/A'}</h6>
                    <h6>Percentage: {edu.percentage || 'N/A'}</h6>
                    <h6>description: {edu.description || 'N/A'}</h6>
                  </div>
                ))
              ) : (
                <p>No work experience found.</p>
              )}
            </ToggleComponent>

            <ToggleComponent title="Skill" icon={icons['briefcase.svg']}>
              {profileData.education_data && profileData.education_data.length > 0 ? (
                profileData.education_data.map((edu, index) => (
                  <div key={index}>
                    <h6>Company Name: {edu.degree || 'N/A'}</h6>
                    <h6>Role: {edu.university || 'N/A'}</h6>
                    <h6>Passing Year: {edu.passing_year || 'N/A'}</h6>
                    <h6>Percentage: {edu.percentage || 'N/A'}</h6>
                    <h6>description: {edu.description || 'N/A'}</h6>
                  </div>
                ))
              ) : (
                <p>No work experience found.</p>
              )}
            </ToggleComponent>
          </div>
          <div className="col-md-3">
              <div className="card verticle topcompanies p-4">
              <ProfileComplete/>
              </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileOverview;
