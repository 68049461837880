import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchData } from '../../services/apiServices'; // Adjust path if needed
import './Autocomplete.css'; // Include your custom CSS here

const Autocomplete = ({ endpoint, onSelect, placeholder,icon,isImage = false }) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [allResults, setAllResults] = useState([]); // Store unfiltered results
  const [selected, setSelected] = useState(false); // To track if a suggestion was selected

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (inputValue.trim() === '') {
        setSuggestions([]);
        return;
      }

      const fetchSuggestions = async () => {
        setLoading(true);
        setError(null); // Reset error state

        try {
          const response = await fetchData(endpoint, { query: inputValue });

          console.log('API Response:', response); // Log the entire response to check structure

          if (response && Array.isArray(response.data)) {
            setAllResults(response.data); // Store all results for local filtering
            const filteredSuggestions = response.data.filter(item =>
              item.name.toLowerCase().includes(inputValue.toLowerCase())
            ).map(item => ({
              label: item.name,
              value: item.id,
            }));
            setSuggestions(filteredSuggestions);
          } else {
            console.error('Unexpected response structure:', response);
            setError('Error loading data');
          }
        } catch (err) {
          console.error('Error fetching data:', err); 
          setError('Error loading data');
        } finally {
          setLoading(false);
        }
      };

      fetchSuggestions();
    }, 300); // 300ms debounce

    return () => clearTimeout(debounceTimeout);
  }, [inputValue, endpoint]);

  useEffect(() => {
    if (allResults.length > 0 && inputValue) {
      const filteredSuggestions = allResults.filter(item =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      ).map(item => ({
        label: item.name,
        value: item.id,
      }));
      setSuggestions(filteredSuggestions);
    }
  }, [inputValue, allResults]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setSelected(false); // Reset selected flag when input changes
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.label);
    setSuggestions([]); // Close the suggestions list after selecting an option
    setSelected(true); // Mark suggestion as selected
    onSelect(suggestion);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setSuggestions([]); // Close suggestions on blur after a short delay
    }, 100);
  };

  return (
    <>
    <div className="autocomplete-container input_outer">
      <span className='inputicon'>
          {isImage ? (
            <img src={icon} alt="input icon" style={{ color: '#212529'}} />
          ) : (
            icon // Render the icon component directly
          )}
        </span>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        className="form-control autocomplete-input"
      />
    </div>
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map(suggestion => (
            <li
              key={suggestion.value}
              onClick={() => handleSuggestionClick(suggestion)}
              className="suggestion-item"
            >
              {suggestion.label}
            </li>
          ))}
        </ul>
      )}
      {suggestions.length === 0 && !loading && !error && inputValue && !selected && (
        <div>No suggestions available</div> 
      )}
    </>
  );
};

Autocomplete.propTypes = {
  endpoint: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

Autocomplete.defaultProps = {
  placeholder: 'Type to search...',
};

export default Autocomplete;
