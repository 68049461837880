import React, { useState, useEffect } from 'react';
import icons from "../../assets/icons";

// import workIcon from '../../assets/icons/laptop-2.svg';
// import department from '../../assets/icons/landmark.svg';
// import salaryrange from '../../assets/icons/git-commit.svg';
// import companyICon from '../../assets/icons/Group.svg';
// import postedIcon from '../../assets/icons/calendar.svg';
// import roleCatIcon from '../../assets/icons/layout-grid.svg';
// import educationIcon from '../../assets/icons/briefcase.svg';
// import topCompanyIcon from '../../assets/icons/star.svg';
// import freshnessIcons from '../../assets/icons/calendar-search.svg';


import UpArrowIcon from '../../assets/icons/Vectordown.svg';

import Autocomplete from "../../components/form/Autocomplete";
import DynamicDropdown from '../../components/form/DynamicDropdown';
import CustomButton from '../../components/form/CustomButton';
import InputWithIcon from '../../components/form/InputWithIcon';

const SearchJobs = () =>{
    const userId = localStorage.getItem("login_token");
    


    const [jobRole, setJobRole] = useState('');
    const [workMode, setWorkMode] = useState([]);
    const [salaryRange, setSalaryRange] = useState([]);
    const [postedBy, setPostedBy] = useState([]);
    const [companyType, setCompanyType] = useState([]);
    const [freshness, setFreshness] = useState([]);
    const [advanceSearch, setAdvanceSearch] = useState(false);

    const [diCategory, setDiCategory] = useState('');
    const [gender, setGender] = useState('');
    const [experience, setExperience] = useState('');

    const handleSelect = (suggestion) => {
        console.log('Selected suggestion:', suggestion);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add logic to handle the form submission and search
        console.log({
          jobRole,
          //city,
          workMode,
          salaryRange,
          postedBy,
          companyType,
          freshness,
          //fullName,
          //dateOfBirth,
          diCategory,
          gender,
          experience
        });
      };
    return(
        <>
        <section className='content'>
            <div className='container'>
            {/* <InputWithIcon  
             icon={<img src={icons["search.svg"]} />}
            >
           
            </InputWithIcon> */}
            <Autocomplete
        icon={<img src={icons["search.svg"]}/>}
        className='no-border'
        endpoint='get-skill-list'
        onSelect={handleSelect}
        placeholder="Search by Title, skill or category"
    />
           <InputWithIcon icon={<img src={icons['location_job.svg']} />}>
             <input type='text' placeholder='Location' className='form-control' value=''/>
           </InputWithIcon>
            <div className='advanced-search-toggle'>
              <a href="#" className="d-flex" onClick={() => setAdvanceSearch(!advanceSearch)}
                style={{ alignItems:'center',justifyContent:'space-between'}}
              >
                Advance Search
                {advanceSearch ? 
                  <img src={UpArrowIcon} alt=""/>
                  : <img src={UpArrowIcon} alt="" style={{transform:'rotate(180deg)'}}/>
                }
              </a>
            </div>
            {advanceSearch && (
              <div className='advanced-search-fields'>
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/work-modes" 
                  iconSrc={icons["laptop-2.svg"]}
                  placeholder="Work Mode"
              />
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/detartment" 
                  iconSrc={icons['landmark.svg']}
                  placeholder='Department'
              />
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/salary-ranges" 
                  iconSrc={icons['git-commit.svg']}
                  placeholder='Salary Ranges'
              />
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/company-types" 
                  iconSrc={icons['Group.svg']}
                  placeholder='Company Type'
              />
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/role-category" 
                  iconSrc={icons['calendar.svg']}
                  placeholder='Role Category'
              />
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/education" 
                  iconSrc={icons['layout-grid.svg']}
                  placeholder='Education'
              />
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/posted-by" 
                  iconSrc={icons['briefcase.svg']}
                  placeholder='Posted By'
              />
              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/posted-by" 
                  iconSrc={icons['star.svg']}
                  placeholder='Top Companies'
              />


              <DynamicDropdown 
                  endpoint="https://deijobs.in/deijobs-api/api/freshness" 
                  iconSrc={icons['calendar-search.svg']}
                  placeholder='Freshness'
              />
              </div>
            )}
            <CustomButton onClick={handleSubmit} text='Search Jobs'/>
            </div>
        </section>
        </>
    )
};
export default SearchJobs;